import React, {
  useEffect,
  useState
} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./Button.css";

export default function Button({
  handleClick,
  text,
  disabled = false,
  loading,
  isSecondary = false
}) {

  function onClick () {
    if (!disabled) {
      handleClick();
    }
  }


  const [wrapperClassName, setWrapperClassName] = useState('');

  useEffect(() => {
    let wrapperClassName = (disabled ? "btn-disabled" : "btn");

    if (isSecondary) {
      wrapperClassName += ' secondary';
    }

    setWrapperClassName(wrapperClassName);
  }, [disabled, isSecondary]);




  return (

    <div className={wrapperClassName} onClick={onClick}>
      {loading === true ? (
        <ClipLoader
          size={50}
          color={"#ffffff"}
          loading={loading}
        />
    ):  <p>{text}</p> }
    </div>
  );
}
