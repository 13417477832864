import React, { useState } from "react";
import Button from "../../components/Button/Button";
import { useHistory } from "react-router-dom";
import "./FirstStep.css";
import svgs from "../../Svgs";
import PhoneNrInput from "../../components/PhoneInput/PhoneNrInput";

import {
  createPhoneCheck,
  getPhoneCheckResult
} from '../../api/truPhoneCheck';

import {
  useSetHeaderToFullScreen
} from '../../shared/hooks';


const VERIFY_TIMEOUT_MS = 5000;

let isVerifying = false;
let verifyingHasTimedOut = false;
let verifyProcessStartTimestamp = null;
let checkVerifyTimeTimeout;

let createChecker, resultChecker;


const FirstStep = () => {
  let history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const [checkState, setCheckState] = useState("");
  const [phoneNr, setPhoneNr] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const [identifier, setIdentifier] = useState('');
  const [stateCheckId, setStateCheckId] = useState("");
  const [checkURL, setCheckURL] = useState("");


  const handleChange = (val) => {
    setPhoneNr(val);
  };


  function goToTwoFactorAuthPage (phoneNumber) {
    console.log('goToTwoFactorAuthPage', Date.now())
    // return;
    history.push("/two-factor-auth", {
      phoneNumber
    });
  }

  function goToCompletePage () {
    console.log('goToCompletePage', Date.now())
    // return;
    history.replace("/complete");
  }


  async function validatePhoneCheck (identifier, checkId, checkURL) {

    if (verifyingHasTimedOut) {
      return;
    }

    resultChecker = getPhoneCheckResult(identifier, checkId);
    const getPhoneCheckResultResult = await resultChecker.promise;

    if (verifyingHasTimedOut) {
      return;
    }

    console.log('validatePhoneCheck getPhoneCheckResultResult', getPhoneCheckResultResult);

    clearTimeout(checkVerifyTimeTimeout);
    setLoading(false);
    isVerifying = false;


    if (getPhoneCheckResultResult.success) {
      const {
        status,
        match
      } = getPhoneCheckResultResult;

      // setCheckState(status)
      setCheckState(status !== 'ACCEPTED' ? status : '');

      if (status.toLowerCase() === "PENDING".toLowerCase()) {
        return
      }

      // setVerified(status === "ACCEPTED" || status === "COMPLETED");

      setVerified(match);
      console.log("Match: ", match, Date.now())

      if (match) {
        goToCompletePage();
      } else if (status === 'ERROR') {
        goToTwoFactorAuthPage(phoneNr);
      }
    } else {
      setCheckState("Failed")

      console.log(
        "Error in validating Final Phone Check",
        getPhoneCheckResultResult.error
      );

      goToTwoFactorAuthPage(phoneNr);
    }
  }

  async function handleClick () {
    verifyingHasTimedOut = false;
    verifyProcessStartTimestamp = Date.now();
    console.log('verifyProcessStartTimestamp', verifyProcessStartTimestamp);

    isVerifying = true;
    setLoading(true);


    function timeoutChecker () {
      const nowTimestamp = Date.now();

      console.log('validatePhoneCheck nowTimestamp', nowTimestamp);

      if (isVerifying
        && nowTimestamp - verifyProcessStartTimestamp >= VERIFY_TIMEOUT_MS) {

        console.log('Time to stop verification process');

        createChecker && createChecker.abort();
        resultChecker && resultChecker.abort();

        verifyingHasTimedOut = true;
        isVerifying = false;

        goToTwoFactorAuthPage(phoneNr);
      } else if (isVerifying) {
        checkVerifyTimeTimeout = setTimeout(timeoutChecker, 100);
      }
    }

    checkVerifyTimeTimeout = setTimeout(timeoutChecker, 100);


    if (checkState === "") {
      createChecker = createPhoneCheck(phoneNr);
      const createPhoneCheckResult = await createChecker.promise;
      console.log('handleClick createPhoneCheckResult', createPhoneCheckResult);

      if (createPhoneCheckResult.success) {

        const {
          identifier,
          checkID,
          checkURL
        } = createPhoneCheckResult;

        setIdentifier(identifier);
        setStateCheckId(checkID);
        setCheckURL(checkURL);

        setTimeout(() => {
          validatePhoneCheck(identifier, checkID, checkURL);
        }, 1000);
      } else {
        setLoading(false);
        isVerifying = false;
      }
    } else {
      validatePhoneCheck(identifier, stateCheckId, checkURL);
    }

  }


  useSetHeaderToFullScreen('App-header-main');


  return (
    <div className="FirstStep">
      <div className="App-header-main FirstStep-header">

        <div className="top">
          <div className="back" onClick={handleBack}>
            <img alt="back" src={svgs.ChevronLeft} />
          </div>

          <p className="mini-desc">Please enter your mobile number.</p>

          <div className="phone-nr">
            <div className="give-width">
              <PhoneNrInput
                changeDisable={(boolVal) => {
                  setDisabled(boolVal);
                }}
                isVerified={verified}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>

        {checkState !== "" ? <p className="mini-desc"> Check State: {checkState} </p> : null }

        <div className="bottom" style={{marginBottom: '40vh'}}>
          <Button
            text={checkState !== "" ? "Retry" : "Verify"}
            handleClick={handleClick}
            disabled={disabled || loading}
            loading={loading} />
        </div>

      </div>
    </div>
  );
};

export default FirstStep;
