import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import styled, {
  createGlobalStyle
} from "styled-components";

import "./App.css";

import FirstStep from "./Pages/FirstStep/FirstStep";
import LandingPage from "./Pages/LandingPage/LandingPage";
import TwoFactorAuth from './Pages/TwoFactorAuth/TwoFactorAuth';
import Complete from './Pages/Complete/Complete';

import {
  colours
} from "./StyledComponents";


const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    margin: 0 auto;
  }
`;
const AppContainer = styled.div`
  background-color: ${colours.black};
  height: 100%;
`;


const App = () => {

  return (
    <AppContainer>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/" component={LandingPage} exact />
          <Route path="/first" component={FirstStep} exact />
          <Route path="/two-factor-auth" component={TwoFactorAuth} exact />
          <Route path="/complete" component={Complete} exact />
        </Switch>
      </Router>
    </AppContainer>
  );
}

export default App;
