import React, {
  useState,
  useEffect,
  useCallback
} from "react";

import {
  useHistory
} from "react-router-dom";

import "./TwoFactorAuth.css";


import {
  sendSMS
} from '../../api/twilio';

import svgs from "../../Svgs";

import Button from "../../components/Button/Button";

import {
  useSetHeaderToFullScreen
} from '../../shared/hooks';


const VERIFICATION_CODE_LENGTH = 6;

// from min up to but not including max
function getRandomInt (min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

function createVerificationCode () {
  return getRandomInt(
    Math.pow(10, VERIFICATION_CODE_LENGTH - 1),
    Math.pow(10, VERIFICATION_CODE_LENGTH)
  ).toString();
}


export default function TwoFactorAuth () {

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);

  const [sendingMessage, setSendingMessage] = useState(false);

  function onSendNewCodeClick () {
    const verificationCode = createVerificationCode();

    setVerificationCode(verificationCode);

    sendVerificationCode(phoneNumber, verificationCode);
  }

  const sendVerificationCode = useCallback(async (phoneNumber, verificationCode) => {

    setSendingMessage(true);

    await sendSMS(
      phoneNumber,
      `${verificationCode} is your Bound authentication code.`
      + `\n@${window.location.host} #${verificationCode}`
    );

    setSendingMessage(false);
  }, []);


  let history = useHistory();

  const handleBack = () => {
    history.goBack();
  };


  useEffect(() => {
    const phoneNumber = history.location.state.phoneNumber;

    setPhoneNumber(phoneNumber);

    const verificationCode = createVerificationCode();

    setVerificationCode(verificationCode);

    sendVerificationCode(phoneNumber, verificationCode);
  }, [history.location, sendVerificationCode]);


  const [codesMatch, setCodesMatch] = useState(true);


  const [validationInputValue, setValidationInputValue] = useState('');

  function onValidationInputValueChange (evt) {
    setCodesMatch(true);
    setValidationInputValue(evt.target.value);
  }


  function goToCompletePage () {
    history.replace("/complete");
  }

  function validateCode () {
    setCodesMatch(true);

    if (validationInputValue.trim() === verificationCode) {
      console.log('Code matches');
      goToCompletePage();
    } else {
      console.log('Codes don\'t match');
      setCodesMatch(false);
    }

  }


  useSetHeaderToFullScreen('App-header');


  return (
    <div className="TwoFactorAuth">
      <div className="App-header TwoFactorAuth-header">

        <div className="top">
          <div className="back" onClick={handleBack}>
            <img alt="back" src={svgs.ChevronLeft} />
          </div>


          <form
            onSubmit={(evt) => evt.preventDefault()}
            className="validation-code-form">

            <p className="heading-text">
              Verification Code
            </p>

            <p className="mini-desc">
              We sent you a verification code via text message.<br />
              Please enter the verification code below.
            </p>

            <input
              type="text"
              placeholder="Enter verification code"
              value={validationInputValue}
              onChange={onValidationInputValueChange}
              className="validation-code-input" />

            {/*
            <input
              type="submit"
              value="Check Validation Code"
              className="validation-code-submit-button" />
              */}

            {!codesMatch && (
              <p className="mini-desc validation-code-error">
                The verification code you entered doesn't match what we sent.
                Please try again
              </p>
            )}
          </form>
        </div>


        <div className="bottom bottom-space" style={{marginBottom: '40vh'}}>

          <Button
            text="Send me a new code"
            handleClick={onSendNewCodeClick}
            disabled={sendingMessage}
            loading={sendingMessage}
            isSecondary />

          <Button
            text="Continue"
            handleClick={validateCode}
            disabled={validationInputValue.trim() === ''} />

        </div>

      </div>
    </div>
  );
}
