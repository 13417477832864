/*-- Styling from Zeplin --*/

export const colours = {
  blue: `#0091FF`,
  darkBlue: `#0071C7`,
  green: `#6DD400`,
  red: `#E02020`,
  black: `#333333`,
  greyDark: `#999999`,
  greyMedium: `#BBBBBB`,
  greyLight: `#CCCCCC`,
  greyLighter: `#E5E5E5`,
  greyLightest: `#FAFAFA`,
  greyText: `#979797`,
  white: `#FFFFFF`,
};
