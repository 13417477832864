import React from "react";
import "./LandingPage.css";
import boundLogo from "../../assets/bound-logo-trimmed.png";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";

import {
  useSetHeaderToFullScreen
} from '../../shared/hooks';

const LandingPage = () => {
  let history = useHistory();

  const handleClick = () => {
    history.push("/first");
  };


  useSetHeaderToFullScreen('App-header-main');


  return (
    <div className="LandingPage">
      <div className="App-header-main LandingPage-header">
        <div></div>

        <div className="bottom">
          <img alt=" " src={boundLogo} className="img" />

          <div className="mini-sect">
            <p className="heading-text">Instantly sign up using Magic 2FA</p>

            <Button text={"Sign Up"} handleClick={handleClick} />
          </div>
        </div>

      </div>
    </div>
  );
};

export default LandingPage;
