import React, { useState, useMemo, useEffect } from "react";

import PhoneInput, {
  getCountryCallingCode
} from "react-phone-number-input";

import "react-phone-number-input/style.css";
import "./PhoneNrInput.css";

import svgs from "../../Svgs";

import styled from 'styled-components';


const Wrapper = styled.div`
  & .PhoneInputInput {
    padding-right: ${props => props.isVerified && '50px'}
  }
`;

const CountrySelectorWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 22px;
  margin-right: 10px;
`;

const CountrySelectorSelectMenu = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
`;

const CountrySelectorFlagIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;


function getSelectedOption(options, value) {
	for (const option of options) {
		if (!option.divider && option.value === value) {
			return option;
		}
	}
}


function CountrySelector ({
  value,
  options,
  onChange,
  iconComponent: Icon,
  unicodeFlags
}) {

  // console.log('CountrySelector value', value);
  // console.log('CountrySelector options', options);
  // console.log('');

  function onValueChange (evt) {
    onChange(evt.target.value || 'GB');
  }

  const selectedOption = useMemo(() => {
		return getSelectedOption(options, value);
	}, [options, value])

  // console.log('selectedOption', selectedOption);


  return (
    <CountrySelectorWrapper>

      <CountrySelectorFlagIconWrapper>
        <Icon
          country={value === 'International' ? undefined : value}
          label={(selectedOption && selectedOption.label) || 'International'}
          aspectRatio={unicodeFlags ? 1 : undefined} />
      </CountrySelectorFlagIconWrapper>

      <CountrySelectorSelectMenu
        value={value}
        onChange={onValueChange}>

        {options.map(({ value, label, divider }) => (
          <option
            key={value || 'international'}
            value={value}>
            {label}
          </option>
        ))}

      </CountrySelectorSelectMenu>

    </CountrySelectorWrapper>
  );
}


export default function PhoneNrInput({
  changeDisable,
  isVerified = false,
  handleChange,
}) {

  const [value, setValue] = useState();
  const [countryCode, setCountryCode] = useState('GB');
  const [countryCallingCode, setCountryCallingCode] = useState('44');

  useEffect(() => {
    if (countryCode) {
      setCountryCallingCode(getCountryCallingCode(countryCode));
    }
  }, [countryCode]);


  return (
    <Wrapper
      className="wrapp"
      isVerified={isVerified}>
      
      <span className="country-code">
        ({countryCallingCode})
      </span>

      <PhoneInput
        placeholder="e.g.077987654210"

        value={value}

        defaultCountry="GB"
        country={countryCode}

        onChange={(val) => {
          // console.log('PhoneNrInput onChange val', val);
          // console.log('');

          handleChange(val);
          setValue(val);

          if (val) {
            changeDisable(false);
          } else {
            changeDisable(true);
          }
        }}

        // addInternationalOption={false}
        countrySelectComponent={CountrySelector}

        onCountryChange={(val) => {
          // console.log('PhoneNrInput onCountryChange val', val);

          setCountryCode(val);

          // if (val) {
          //   setCountryCode(val);
          // } else {
          //   setCountryCode(countryCode);
          // }
        }} />

      {isVerified && (
        <div className="verify-badge">
          <img alt="verified" src={svgs.CheckCircle} />
          <p>verified</p>
        </div>
      )}
    </Wrapper>
  );
}
