import React from "react";

import {
  useHistory
} from "react-router-dom";

import "./Complete.css";


import Button from "../../components/Button/Button";

import {
  useSetHeaderToFullScreen
} from '../../shared/hooks';


export default function Complete () {
  let history = useHistory();

  function onLogOutButtonClick () {
    history.push('/')
  };


  useSetHeaderToFullScreen('App-header');


  return (
    <div className="Complete">
      <div className="App-header Complete-header">

        <div className="top">
          <p className="easy-text">That was <br />easy!</p>
        </div>


        <div className="bottom">
          <Button text="Log out" handleClick={onLogOutButtonClick} />
        </div>

      </div>
    </div>
  );
}
