import truID from '@tru_id/tru-sdk-web';


import {
  getFullURL,
  backendApiCall
} from './backend';


async function createPhoneCheckAPI (phoneNumber, signal) {
  console.log('checkNumber phoneNumber', phoneNumber);

  const url = getFullURL('/create-phone-check');

  const result = await backendApiCall(url, {
    method: 'POST',
    body: JSON.stringify({
      phoneNumber: phoneNumber.replace('+', '')
    }),
    signal
  });

  if (!result.success) {
    return result;
  }

  try {
    let {
      identifier,
      checkID,
      checkURL
    } = result;

    if (result.checkPlatform === 'tru') {
      await truID.openCheckUrl(checkURL);
    } if (result.checkPlatform === 'o2') {
      const response = await fetch(checkURL, {signal});
      const responseJSON = await response.json();
      checkID = responseJSON.token;
      console.log('checkID', checkID);

      // await truID.openCheckUrl(checkURL);

      // const img = new Image()
  		// img.style.height = 0
  		// img.style.width = 0
  		// img.setAttribute('referrerpolicy', 'no-referrer')
      // img.src = checkURL;
      // img.onload = () => {
      //   console.log('loaded');
      // };
      // document.body.appendChild(img);
    }

    return {
      success: true,
      identifier,
      checkID,
      checkURL
    };
  } catch (error) {
    return {
      success: false,
      error
    };
  }
}

export function createPhoneCheck (phoneNumber) {
  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    promise: createPhoneCheckAPI(phoneNumber, signal)
  };
}


export function getPhoneCheckResult (identifier, checkID) {
  let url = getFullURL('/get-phone-check-result');

  url += `?identifier=${encodeURIComponent(identifier)}`;
  url += `&checkID=${encodeURIComponent(checkID)}`;

  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    promise: backendApiCall(url, {signal})
  };
}
